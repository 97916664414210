import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getUserRoles } from "utils/apiRequestBuilder";
import LoaderAnimation from "utils/animation/loader";
import Layout from ".";

const RoleCheckRoute = ({ children }) => {
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state
  const location = useLocation();
  const WriteRole = "MessageCentreWriter";

  const fetchUserRoles = async () => {
    try {
      const [request, options] = await getUserRoles();
      const response = await fetch(request, options);
      if (response.status === 200) {
        const json = await response.json();
        setUserRoles(json.data.map((role) => role.name));
      } else {
        console.error("Error fetching user roles:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching user roles:", error);
    } finally {
      setLoading(false); // Ensure loading is set to false
    }
  };

  useEffect(() => {
    fetchUserRoles();
  }, []);

  if (loading) {
    // Show a loading spinner or placeholder until the roles are fetched
    return <Layout>{/* <LoaderAnimation /> */}</Layout>;
  }

  return userRoles.includes(WriteRole) ? (
    children
  ) : (
    <Navigate to="/messages" state={{ from: location }} replace />
  );
};

export default RoleCheckRoute;
